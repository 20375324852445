<template>
  <vx-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full mt-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input label="Libellé" v-model="libelle" class="w-full" name="libelle" />
          </div>
          <div class="vx-col w-full mt-5">
            <div class="vx-row">
              <div class="vx-col">
                <div class="flex items-center mb-4">
                  <span class="mr-4">Status</span>
                  <vs-switch class="mr-4" v-model="status" />
                </div>
              </div>
              <div class="vx-col">
                <template v-if="!parametrage">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Paramétrage</span>
                    <vs-switch class="mr-4" v-model="parametrage" @change="removeParametragePrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Paramétrage</span>
                    <vs-switch class="mr-4" v-model="parametrage" @change="addParametragePrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!gestion_stock">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Gestion de stock</span>
                    <vs-switch class="mr-4" v-model="gestion_stock" @change="removeGestionStockPrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Gestion de stock</span>
                    <vs-switch class="mr-4" v-model="gestion_stock" @change="addGestionStockPrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!exploitation">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Exploitation</span>
                    <vs-switch class="mr-4" v-model="exploitation" @change="removeExploitationPrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Exploitation</span>
                    <vs-switch class="mr-4" v-model="exploitation" @change="addExploitationPrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!caisse">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Caisse</span>
                    <vs-switch class="mr-4" v-model="caisse" @change="removeCaissePrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Caisse</span>
                    <vs-switch class="mr-4" v-model="caisse" @change="addCaissePrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!facturation">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Facturation</span>
                    <vs-switch class="mr-4" v-model="facturation" @change="removeFacturationPrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Facturation</span>
                    <vs-switch class="mr-4" v-model="facturation" @change="addFacturationPrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!comptabilite">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Comptabilité</span>
                    <vs-switch class="mr-4" v-model="comptabilite" @change="removeComptabilitePrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Comptabilité</span>
                    <vs-switch class="mr-4" v-model="comptabilite" @change="addComptabilitePrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!sms_marketig">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">SMS Marketing</span>
                    <vs-switch class="mr-4" v-model="sms_marketig" @change="removeSmsMarketingPrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">SMS Marketing</span>
                    <vs-switch class="mr-4" v-model="sms_marketig" @change="addSmsMarketingPrivilege"/>
                  </div>
                </template>
              </div>
              <div class="vx-col">
                <template v-if="!edition_stat">
                  <div class="flex items-center mb-4">
                    <span class="mr-4">Edition & Stat</span>
                    <vs-switch class="mr-4" v-model="edition_stat" @change="removeEditionStatPrivilege"/>
                  </div>
                </template>
                <template v-else>
                    <div class="flex items-center mb-4">
                    <span class="mr-4">Edition & Stat</span>
                    <vs-switch class="mr-4" v-model="edition_stat" @change="addEditionStatPrivilege"/>
                  </div>
                </template>
              </div>

            </div>
          </div>
          <div class="vx-col w-full">
            <!-- {{privileges}} -->
            <vx-card :noShadow="true" :cardBorder="true" title="Paramétrage">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="officine" @change="addPrivilegeParametrage('officine')">Offic.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="personnel" @change="addPrivilegeParametrage('personnel')">Pers.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="utilisateur" @change="addPrivilegeParametrage('utilisateur')">Util.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="fonction" @change="addPrivilegeParametrage('fonction')">Fonc.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="societe" @change="addPrivilegeParametrage('societe')">Socie.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="banque" @change="addPrivilegeParametrage('banque')">Banq.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="assurance" @change="addPrivilegeParametrage('assurance')">Assu</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="centre_hospitalier" @change="addPrivilegeParametrage('centre_hospitalier')">Cent.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="prescripteur" @change="addPrivilegeParametrage('prescripteur')">Pres.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="categorie_article" @change="addPrivilegeParametrage('categorie_article')">CAr.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="profession" @change="addPrivilegeParametrage('profession')">Profe.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="partenaire" @change="addPrivilegeParametrage('partenaire')">Parte.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="fournisseur" @change="addPrivilegeParametrage('fournisseur')">Fourn.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="marque" @change="addPrivilegeParametrage('marque')">Marq</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="type" @change="addPrivilegeParametrage('type')">Type.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="style" @change="addPrivilegeParametrage('style')">Style</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="pays" @change="addPrivilegeParametrage('pays')">Pays.</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="ville" @change="addPrivilegeParametrage('ville')">Ville</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="quartier" @change="addPrivilegeParametrage('quartier')">Quart</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="role" @change="addPrivilegeParametrage('role')">Role</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="rubrique_depense" @change="addPrivilegeParametrage('rubrique_depense')">Rub.depse</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="four_verre" @change="addPrivilegeParametrage('four_verre')">F.Ver</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="catalogue_verre" @change="addPrivilegeParametrage('catalogue_verre')">C.Ver</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="entete_pied_page" @change="addPrivilegeParametrage('entete_pied_page')">Entete.piedPage</vs-checkbox>
                </div>

              </div>

            </vx-card>
          </div>
          <div class="vx-col w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Gestion de stock" class="mt-2">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="liste_br" @change="addPrivilegeGestionStock('liste_br')">Liste BR</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="nouveau_br" @change="addPrivilegeGestionStock('nouveau_br')">Nouv. BR</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="br_transfere" @change="addPrivilegeGestionStock('br_transfere')">BR Transf</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="stock" @change="addPrivilegeGestionStock('stock')">Stock</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="stock_verre" @change="addPrivilegeGestionStock('stock_verre')">Stock verre</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="inventaire" @change="addPrivilegeGestionStock('inventaire')">Inventaire</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="mise_a_jour_iventaire" @change="addPrivilegeGestionStock('mise_a_jour_iventaire')">Mise à jour du stock</vs-checkbox>
                </div>

              </div>

            </vx-card>
          </div>
          <div class="vx-col w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Exploitation" class="mt-2">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="client" @change="addPrivilegeExploitation('client')">Client</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="ordonnance" @change="addPrivilegeExploitation('ordonnance')">Ordonnance</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="proforma" @change="addPrivilegeExploitation('proforma')">Proforma</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="commande" @change="addPrivilegeExploitation('commande')">Commande</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="vente" @change="addPrivilegeExploitation('vente')">Vente</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="atelier" @change="addPrivilegeExploitation('atelier')">Atelier</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-5" v-model="examen_vue" @change="addPrivilegeExploitation('examen_vue')">Examen de vue</vs-checkbox>
                </div>

              </div>

            </vx-card>
          </div>
          <div class="vx-col w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Caisse" class="mt-2">
              <div class="vx-row">
                  <div class="vx-col">
                    <vs-checkbox class="mt-5" v-model="caisse_recette" @change="addPrivilegeCaisse('caisse_recette')">Caisse recette</vs-checkbox>
                  </div>
                  <div class="vx-col">
                    <vs-checkbox class="mt-5" v-model="caisse_depense" @change="addPrivilegeCaisse('caisse_depense')">Caisse depense</vs-checkbox>
                  </div>
                  <div class="vx-col">
                    <vs-checkbox class="mt-5" v-model="operation" @change="addPrivilegeCaisse('operation')">Opérations</vs-checkbox>
                  </div>

              </div>

            </vx-card>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5">
        <div class="vx-row">

          <vx-card :noShadow="true" :cardBorder="true" title="Facturation" class="mt-2">
            <div class="vx-row">
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="traitement_fact" @change="addPrivilegeFacturation('traitement_fact')">Traitement Fact</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="modele_fact" @change="addPrivilegeFacturation('modele_fact')">Modèle Fact</vs-checkbox>
              </div>
            </div>
          </vx-card>
          <vx-card :noShadow="true" :cardBorder="true" title="Comptabilité" class="mt-2">
            <div class="vx-row">
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="registre" @change="addPrivilegeComptabilite('registre')">Registre</vs-checkbox>
              </div>
            </div>
          </vx-card>
          <vx-card :noShadow="true" :cardBorder="true" title="SMS Marketing" class="mt-2">
            <div class="vx-row">
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="campagne_sms" @change="addPrivilegeSmsMarketing('campagne_sms')">Campagne SMS</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="anniv_client" @change="addPrivilegeSmsMarketing('anniv_client')">Anniv. Client</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="anniv_commande" @change="addPrivilegeSmsMarketing('anniv_commande')">Anniv. Commande</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="alerte_proforma" @change="addPrivilegeSmsMarketing('alerte_proforma')">Alerte Proforma</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="alerte_suivi_commande" @change="addPrivilegeSmsMarketing('alerte_suivi_commande')">Suivi commande</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="compte_sms" @change="addPrivilegeSmsMarketing('compte_sms')">Compte</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="repertoire" @change="addPrivilegeSmsMarketing('repertoire')">Répertoire</vs-checkbox>
              </div>
            </div>
          </vx-card>
          <vx-card :noShadow="true" :cardBorder="true" title="Edition & Stat" class="mt-2">
            <div class="vx-row">
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="edition_ca" @change="addPrivilegeEditionStat('edition_ca')">CA</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="edition_suivi_regl" @change="addPrivilegeEditionStat('edition_suivi_regl')">Suivi reglement</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="edition_caisse_recette" @change="addPrivilegeEditionStat('edition_caisse_recette')">Caisse recette</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="edition_caisse_depense" @change="addPrivilegeEditionStat('edition_caisse_depense')">Caisse depense</vs-checkbox>
              </div>
              <div class="vx-col">
                <vs-checkbox class="mt-5" v-model="stat" @change="addPrivilegeEditionStat('stat')">Stat</vs-checkbox>
              </div>
            </div>
          </vx-card>

          <div class="vx-col">
            <vs-checkbox class="mt-5" v-model="create" @change="addPrivilege('create')">Créer</vs-checkbox>
          </div>
          <div class="vx-col">
            <vs-checkbox class="mt-5" v-model="update" @change="addPrivilege('update')">Modifier</vs-checkbox>
          </div>
          <div class="vx-col">
            <vs-checkbox class="mt-5" v-model="Delete" @change="addPrivilege('delete')">Supprimer</vs-checkbox>
          </div>
        </div>
      </div>


    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full ml-auto ">
        <vs-button class="mr-3 mb-2" @click="addRole()">Enregistrer</vs-button>
        <vs-button color="warning" type="border" class="mb-2" @click="initidata()">Annuler</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  data () {
    return {
      libelle: '',
      status: true,
      privileges: [],
      parametrageArray: [],
      gestionStockArray: [],
      exploitationArray: [],
      caisseArray: [],
      facturationArray: [],
      comptabiliteArray: [],
      smsMarketingArray: [],
      editionStatArray: [],

      create: false,
      Delete: false,
      update: false,
      tous: false,

      parametrage: false,
      gestion_stock: false,
      exploitation: false,
      caisse: false,
      facturation: false,
      comptabilite: false,
      sms_marketig: false,
      edition_stat: false,

      officine: false,
      personnel: false,
      utilisateur: false,
      fonction: false,
      societe: false,
      banque: false,
      assurance: false,
      centre_hospitalier: false,
      prescripteur: false,
      categorie_article: false,
      profession: false,
      partenaire: false,
      fournisseur: false,
      marque: false,
      type: false,
      style: false,
      pays: false,
      ville: false,
      quartier: false,
      role: false,
      rubrique_depense: false,
      four_verre: false,
      catalogue_verre: false,
      entete_pied_page: false,

      liste_br: false,
      nouveau_br: false,
      br_transfere: false,
      stock: false,
      stock_verre: false,
      inventaire: false,
      mise_a_jour_iventaire: false,

      client: false,
      ordonnance: false,
      proforma: false,
      commande: false,
      vente: false,
      atelier: false,
      examen_vue: false,

      caisse_recette: false,
      caisse_depense: false,
      operation: false,


      traitement_fact: false,
      modele_fact: false,

      registre: false,

      campagne_sms: false,
      anniv_client: false,
      anniv_commande: false,
      alerte_proforma: false,
      alerte_suivi_commande: false,
      compte_sms: false,
      repertoire: false,

      edition_ca: false,
      edition_suivi_regl: false,
      edition_caisse_recette: false,
      edition_caisse_depense: false,
      stat: false
    }
  },
  computed: {

  },
  methods: {
    addParametragePrivilege () {
      if (this.privileges.indexOf('parametrage') > -1) {
        /*  */
      } else {
        this.addPrivilegeParametrage('parametrage')
      }
      this.officine = true
      this.addPrivilegeParametrage('officine')
      this.personnel = true
      this.addPrivilegeParametrage('personnel')
      this.utilisateur = true
      this.addPrivilegeParametrage('utilisateur')
      this.fonction = true
      this.addPrivilegeParametrage('fonction')
      this.societe = true
      this.addPrivilegeParametrage('societe')
      this.banque = true
      this.addPrivilegeParametrage('banque')
      this.assurance = true
      this.addPrivilegeParametrage('assurance')
      this.centre_hospitalier = true
      this.addPrivilegeParametrage('centre_hospitalier')
      this.prescripteur = true
      this.addPrivilegeParametrage('prescripteur')
      this.categorie_article = true
      this.addPrivilegeParametrage('categorie_article')
      this.profession = true
      this.addPrivilegeParametrage('profession')
      this.partenaire = true
      this.addPrivilegeParametrage('partenaire')
      this.fournisseur = true
      this.addPrivilegeParametrage('fournisseur')
      this.marque = true
      this.addPrivilegeParametrage('marque')
      this.type = true
      this.addPrivilegeParametrage('type')
      this.style = true
      this.addPrivilegeParametrage('style')
      this.pays = true
      this.addPrivilegeParametrage('pays')
      this.ville = true
      this.addPrivilegeParametrage('ville')
      this.quartier = true
      this.addPrivilegeParametrage('quartier')
      this.role = true
      this.addPrivilegeParametrage('role')
      this.rubrique_depense = true
      this.addPrivilegeParametrage('rubrique_depense')
      this.four_verre = true
      this.addPrivilegeParametrage('four_verre')
      this.catalogue_verre = true
      this.addPrivilegeParametrage('catalogue_verre')
      this.entete_pied_page = true
      this.addPrivilegeParametrage('entete_pied_page')

    },
    removeParametragePrivilege () {
      this.officine = false
      this.removePrivilegeParametrage('officine')
      this.removePrivilegeParametrage('officine')
      this.personnel = false
      this.removePrivilegeParametrage('personnel')
      this.removePrivilegeParametrage('personnel')
      this.utilisateur = false
      this.removePrivilegeParametrage('utilisateur')
      this.removePrivilegeParametrage('utilisateur')
      this.fonction = false
      this.removePrivilegeParametrage('fonction')
      this.removePrivilegeParametrage('fonction')
      this.societe = false
      this.removePrivilegeParametrage('societe')
      this.removePrivilegeParametrage('societe')
      this.banque = false
      this.removePrivilegeParametrage('banque')
      this.removePrivilegeParametrage('banque')
      this.assurance = false
      this.removePrivilegeParametrage('assurance')
      this.removePrivilegeParametrage('assurance')
      this.centre_hospitalier = false
      this.removePrivilegeParametrage('centre_hospitalier')
      this.removePrivilegeParametrage('centre_hospitalier')
      this.prescripteur = false
      this.removePrivilegeParametrage('prescripteur')
      this.removePrivilegeParametrage('prescripteur')
      this.categorie_article = false
      this.removePrivilegeParametrage('categorie_article')
      this.removePrivilegeParametrage('categorie_article')
      this.profession = false
      this.removePrivilegeParametrage('profession')
      this.removePrivilegeParametrage('profession')
      this.partenaire = false
      this.removePrivilegeParametrage('partenaire')
      this.removePrivilegeParametrage('partenaire')
      this.fournisseur = false
      this.removePrivilegeParametrage('fournisseur')
      this.removePrivilegeParametrage('fournisseur')
      this.marque = false
      this.removePrivilegeParametrage('marque')
      this.removePrivilegeParametrage('marque')
      this.type = false
      this.removePrivilegeParametrage('type')
      this.removePrivilegeParametrage('type')
      this.style = false
      this.removePrivilegeParametrage('style')
      this.removePrivilegeParametrage('style')
      this.pays = false
      this.removePrivilegeParametrage('pays')
      this.removePrivilegeParametrage('pays')
      this.ville = false
      this.removePrivilegeParametrage('ville')
      this.removePrivilegeParametrage('ville')
      this.quartier = false
      this.removePrivilegeParametrage('quartier')
      this.removePrivilegeParametrage('quartier')
      this.role = false
      this.removePrivilegeParametrage('role')
      this.removePrivilegeParametrage('role')
      this.rubrique_depense = false
      this.removePrivilegeParametrage('rubrique_depense')
      this.removePrivilegeParametrage('rubrique_depense')
      this.four_verre = false
      this.removePrivilegeParametrage('four_verre')
      this.removePrivilegeParametrage('four_verre')
      this.catalogue_verre = false
      this.removePrivilegeParametrage('catalogue_verre')
      this.removePrivilegeParametrage('catalogue_verre')
      this.entete_pied_page = false
      this.removePrivilegeParametrage('entete_pied_page')
      this.removePrivilegeParametrage('entete_pied_page')

    },
    addGestionStockPrivilege () {
      if (this.privileges.indexOf('gestion_stock') > -1) {
        /*  */
      } else {
        this.addPrivilegeGestionStock('gestion_stock')
      }
      this.liste_br = true
      this.addPrivilegeGestionStock('liste_br')
      this.nouveau_br = true
      this.addPrivilegeGestionStock('nouveau_br')
      this.br_transfere = true
      this.addPrivilegeGestionStock('br_transfere')
      this.stock = true
      this.addPrivilegeGestionStock('stock')
      this.stock_verre = true
      this.addPrivilegeGestionStock('stock_verre')
      this.inventaire = true
      this.addPrivilegeGestionStock('inventaire')
      this.mise_a_jour_iventaire = true
      this.addPrivilegeGestionStock('mise_a_jour_iventaire')

    },
    removeGestionStockPrivilege () {
      this.liste_br = false
      this.removePrivilegeGestionStock('liste_br')
      this.removePrivilegeGestionStock('liste_br')
      this.nouveau_br = false
      this.removePrivilegeGestionStock('nouveau_br')
      this.br_transfere = false
      this.removePrivilegeGestionStock('br_transfere')
      this.stock = false
      this.removePrivilegeGestionStock('stock')
      this.stock_verre = false
      this.removePrivilegeGestionStock('stock_verre')
      this.inventaire = false
      this.removePrivilegeGestionStock('inventaire')
      this.mise_a_jour_iventaire = false
      this.removePrivilegeGestionStock('mise_a_jour_iventaire')
    },
    addExploitationPrivilege () {
      if (this.privileges.indexOf('exploitation') > -1) {
        /*  */
      } else {
        this.addPrivilegeExploitation('exploitation')
      }
      this.client = true
      this.addPrivilegeExploitation('client')
      this.ordonnance = true
      this.addPrivilegeExploitation('ordonnance')
      this.proforma = true
      this.addPrivilegeExploitation('proforma')
      this.commande = true
      this.addPrivilegeExploitation('commande')
      this.vente = true
      this.addPrivilegeExploitation('vente')
      this.atelier = true
      this.addPrivilegeExploitation('atelier')
      this.examen_vue = true
      this.addPrivilegeExploitation('examen_vue')

    },
    removeExploitationPrivilege () {
      this.client = false
      this.removePrivilegeExploitation('client')
      this.removePrivilegeExploitation('client')
      this.ordonnance = false
      this.removePrivilegeExploitation('ordonnance')
      this.proforma = false
      this.removePrivilegeExploitation('proforma')
      this.commande = false
      this.removePrivilegeExploitation('commande')
      this.vente = false
      this.removePrivilegeExploitation('vente')
      this.atelier = false
      this.removePrivilegeExploitation('atelier')
      this.examen_vue = false
      this.removePrivilegeExploitation('examen_vue')
    },
    addCaissePrivilege () {
      if (this.privileges.indexOf('caisse') > -1) {
        /*  */
      } else {
        this.addPrivilegeCaisse('caisse')
      }
      this.caisse_recette = true
      this.addPrivilegeCaisse('caisse_recette')
      this.caisse_depense = true
      this.addPrivilegeCaisse('caisse_depense')
      this.operation = true
      this.addPrivilegeCaisse('operation')
    },
    removeCaissePrivilege () {
      this.caisse_recette = false
      this.removePrivilegeCaisse('caisse_recette')
      this.removePrivilegeCaisse('caisse_recette')
      this.caisse_depense = false
      this.removePrivilegeCaisse('caisse_depense')
      this.operation = false
      this.removePrivilegeCaisse('operation')

    },
    addFacturationPrivilege () {
      if (this.privileges.indexOf('facturation') > -1) {
        /*  */
      } else {
        this.addPrivilegeFacturation('facturation')
      }
      this.traitement_fact = true
      this.addPrivilegeFacturation('traitement_fact')
      this.modele_fact = true
      this.addPrivilegeFacturation('modele_fact')

    },
    removeFacturationPrivilege () {
      this.traitement_fact = false
      this.removePrivilegeFacturation('traitement_fact')
      this.removePrivilegeFacturation('traitement_fact')
      this.modele_fact = false
      this.removePrivilegeFacturation('modele_fact')

    },
    addComptabilitePrivilege () {
      if (this.privileges.indexOf('comptabilite') > -1) {
        /*  */
      } else {
        this.addPrivilegeComptabilite('comptabilite')
      }
      this.registre = true
      this.addPrivilegeComptabilite('registre')
    },
    removeComptabilitePrivilege () {
      this.registre = false
      this.removePrivilegeComptabilite('registre')
      this.removePrivilegeComptabilite('registre')
    },
    addSmsMarketingPrivilege () {
      if (this.privileges.indexOf('sms_marketig') > -1) {
        /*  */
      } else {
        this.addPrivilegeSmsMarketing('sms_marketig')
      }
      this.campagne_sms = true
      this.addPrivilegeSmsMarketing('campagne_sms')
      this.anniv_client = true
      this.addPrivilegeSmsMarketing('anniv_client')
      this.anniv_commande = true
      this.addPrivilegeSmsMarketing('anniv_commande')
      this.alerte_proforma = true
      this.addPrivilegeSmsMarketing('alerte_proforma')
      this.alerte_suivi_commande = true
      this.addPrivilegeSmsMarketing('alerte_suivi_commande')
      this.compte_sms = true
      this.addPrivilegeSmsMarketing('compte_sms')
      this.repertoire = true
      this.addPrivilegeSmsMarketing('repertoire')
    },
    removeSmsMarketingPrivilege () {
      this.campagne_sms = false
      this.removePrivilegeSmsMarketing('campagne_sms')
      this.removePrivilegeSmsMarketing('campagne_sms')
      this.anniv_client = false
      this.removePrivilegeSmsMarketing('anniv_client')
      this.anniv_commande = false
      this.removePrivilegeSmsMarketing('anniv_commande')
      this.alerte_proforma = false
      this.removePrivilegeSmsMarketing('alerte_proforma')
      this.alerte_suivi_commande = false
      this.removePrivilegeSmsMarketing('alerte_suivi_commande')
      this.compte_sms = false
      this.removePrivilegeSmsMarketing('compte_sms')
      this.repertoire = false
      this.removePrivilegeSmsMarketing('repertoire')

    },
    addEditionStatPrivilege () {
      if (this.privileges.indexOf('edition_stat') > -1) {
        /*  */
      } else {
        this.addPrivilegeEditionStat('edition_stat')
      }
      this.edition_ca = true
      this.addPrivilegeEditionStat('edition_ca')
      this.edition_suivi_regl = true
      this.addPrivilegeEditionStat('edition_suivi_regl')
      this.edition_caisse_recette = true
      this.addPrivilegeEditionStat('edition_caisse_recette')
      this.edition_caisse_depense = true
      this.addPrivilegeEditionStat('edition_caisse_depense')
      this.stat = true
      this.addPrivilegeEditionStat('stat')
    },
    removeEditionStatPrivilege () {
      this.edition_ca = false
      this.removePrivilegeEditionStat('edition_ca')
      this.removePrivilegeEditionStat('edition_ca')
      this.edition_suivi_regl = false
      this.removePrivilegeEditionStat('edition_suivi_regl')
      this.edition_caisse_recette = false
      this.removePrivilegeEditionStat('edition_caisse_recette')
      this.edition_caisse_depense = false
      this.removePrivilegeEditionStat('edition_caisse_depense')
      this.stat = false
      this.removePrivilegeEditionStat('stat')

    },
    addPrivilege (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilege(privilege)
        this.removePrivilege(privilege)
      } else {
        this.privileges.push(privilege)
      }
    },
    addPrivilegeParametrage (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeParametrage(privilege)
        this.removePrivilegeParametrage(privilege)
      } else {
        if (!this.parametrage) {
          this.parametrage = true
          this.addPrivilegeParametrage('parametrage')
        }
        this.privileges.push(privilege)
        this.parametrageArray.push(privilege)
      }
    },
    removePrivilegeParametrage (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.parametrageArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.parametrageArray.splice(index, 1)
        if (this.parametrageArray.length === 1) {
          if (this.parametrageArray[0] === 'parametrage') this.parametrage = false
          this.parametrageArray = []
          const index3 = this.privileges.indexOf('parametrage')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeGestionStock (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeGestionStock(privilege)
        this.removePrivilegeGestionStock(privilege)
      } else {
        if (!this.gestion_stock) {
          this.gestion_stock = true
          this.addPrivilegeGestionStock('gestion_stock')
        }
        this.privileges.push(privilege)
        this.gestionStockArray.push(privilege)
      }
    },
    removePrivilegeGestionStock (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.gestionStockArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.gestionStockArray.splice(index, 1)
        if (this.gestionStockArray.length === 1) {
          if (this.gestionStockArray[0] === 'gestion_stock') this.gestion_stock = false
          this.gestionStockArray = []
          const index3 = this.privileges.indexOf('gestion_stock')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeExploitation (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeExploitation(privilege)
        this.removePrivilegeExploitation(privilege)
      } else {
        if (!this.exploitation) {
          this.exploitation = true
          this.addPrivilegeExploitation('exploitation')
        }
        this.privileges.push(privilege)
        this.exploitationArray.push(privilege)
      }
    },
    removePrivilegeExploitation (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.exploitationArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.exploitationArray.splice(index, 1)
        if (this.exploitationArray.length === 1) {
          if (this.exploitationArray[0] === 'exploitation') this.exploitation = false
          this.exploitationArray = []
          const index3 = this.privileges.indexOf('exploitation')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeCaisse (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeCaisse(privilege)
        this.removePrivilegeCaisse(privilege)
      } else {
        if (!this.caisse) {
          this.caisse = true
          this.addPrivilegeCaisse('caisse')
        }
        this.privileges.push(privilege)
        this.caisseArray.push(privilege)
      }
    },
    removePrivilegeCaisse (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.caisseArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.caisseArray.splice(index, 1)
        if (this.caisseArray.length === 1) {
          if (this.caisseArray[0] === 'caisse') this.caisse = false
          this.caisseArray = []
          const index3 = this.privileges.indexOf('caisse')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeFacturation (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeFacturation(privilege)
        this.removePrivilegeFacturation(privilege)
      } else {
        if (!this.facturation) {
          this.facturation = true
          this.addPrivilegeFacturation('facturation')
        }
        this.privileges.push(privilege)
        this.facturationArray.push(privilege)
      }
    },
    removePrivilegeFacturation (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.facturationArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.facturationArray.splice(index, 1)
        if (this.facturationArray.length === 1) {
          if (this.facturationArray[0] === 'facturation') this.facturation = false
          this.facturationArray = []
          const index3 = this.privileges.indexOf('facturation')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeComptabilite (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeComptabilite(privilege)
        this.removePrivilegeComptabilite(privilege)
      } else {
        if (!this.comptabilite) {
          this.comptabilite = true
          this.addPrivilegeComptabilite('comptabilite')
        }
        this.privileges.push(privilege)
        this.comptabiliteArray.push(privilege)
      }
    },
    removePrivilegeComptabilite (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.comptabiliteArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.comptabiliteArray.splice(index, 1)
        if (this.comptabiliteArray.length === 1) {
          if (this.comptabiliteArray[0] === 'comptabilite') this.comptabilite = false
          this.comptabiliteArray = []
          const index3 = this.privileges.indexOf('comptabilite')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeSmsMarketing (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeSmsMarketing(privilege)
        this.removePrivilegeSmsMarketing(privilege)
      } else {
        if (!this.sms_marketig) {
          this.sms_marketig = true
          this.addPrivilegeSmsMarketing('sms_marketig')
        }
        this.privileges.push(privilege)
        this.smsMarketingArray.push(privilege)
      }
    },
    removePrivilegeSmsMarketing (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.smsMarketingArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.smsMarketingArray.splice(index, 1)
        if (this.smsMarketingArray.length === 1) {
          if (this.smsMarketingArray[0] === 'sms_marketig') this.sms_marketig = false
          this.smsMarketingArray = []
          const index3 = this.privileges.indexOf('sms_marketig')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addPrivilegeEditionStat (privilege) {
      if (this.privileges.indexOf(privilege) > -1) {
        this.removePrivilegeEditionStat(privilege)
        this.removePrivilegeEditionStat(privilege)
      } else {
        if (!this.edition_stat) {
          this.edition_stat = true
          this.addPrivilegeEditionStat('edition_stat')
        }
        this.privileges.push(privilege)
        this.editionStatArray.push(privilege)
      }
    },
    removePrivilegeEditionStat (privilege) {
      const index = this.privileges.indexOf(privilege)
      const index2 = this.editionStatArray.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
        if (index2 > -1) this.editionStatArray.splice(index, 1)
        if (this.editionStatArray.length === 1) {
          if (this.editionStatArray[0] === 'edition_stat') this.edition_stat = false
          this.editionStatArray = []
          const index3 = this.privileges.indexOf('edition_stat')
          this.privileges.splice(index3, 1)
        }
      }
    },
    addRole () {
      const payload = {
        libelle: this.libelle,
        status: this.status,
        privileges: this.privileges
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('role/addRole', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.initidata()
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    initidata () {
      this.libelle = ''
      this.status = true
      this.privileges = []
      this.parametrageArray = []
      this.gestionStockArray = []
      this.exploitationArray = []
      this.caisseArray = []
      this.facturationArray = []
      this.comptabiliteArray = []
      this.smsMarketingArray = []
      this.editionStatArray = []
      this.create = false
      this.Delete = false
      this.update = false
      this.tous = false
      this.parametrage = false
      this.gestion_stock = false
      this.exploitation = false
      this.caisse = false
      this.facturation = false
      this.comptabilite = false
      this.sms_marketig = false
      this.edition_stat = false
      this.officine = false
      this.personnel = false
      this.utilisateur = false
      this.fonction = false
      this.societe = false
      this.banque = false
      this.assurance = false
      this.centre_hospitalier = false
      this.prescripteur = false
      this.categorie_article = false
      this.profession = false
      this.partenaire = false
      this.fournisseur = false
      this.marque = false
      this.type = false
      this.style = false
      this.pays = false
      this.ville = false
      this.quartier = false
      this.role = false
      this.four_verre = false
      this.catalogue_verre = false
      this.liste_br = false
      this.nouveau_br = false
      this.br_transfere = false
      this.stock = false
      this.inventaire = false
      this.mise_a_jour_iventaire = false
      this.client = false
      this.ordonnance = false
      this.proforma = false
      this.commande = false
      this.vente = false
      this.atelier = false
      this.examen_vue = false
      this.caisse_recette = false
      this.caisse_depense = false
      this.operation = false
      this.traitement_fact = false
      this.modele_fact = false
      this.registre = false
      this.campagne_sms = false
      this.anniv_client = false
      this.anniv_commande = false
      this.alerte_proforma = false
      this.alerte_suivi_commande = false
      this.compte_sms = false
      this.repertoire = false
      this.edition_ca = false
      this.edition_suivi_regl = false
      this.edition_caisse_recette = false
      this.edition_caisse_depense = false
      this.stat = false

    },
    hasDuplicates (arr) {
      return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    removePrivilege (privilege) {
      const index = this.privileges.indexOf(privilege)
      if (index > -1) {
        this.privileges.splice(index, 1)
      }
    }
  },
  components: {

  }
}
</script>
